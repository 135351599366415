import React, { useEffect, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import {
    Tabs,
    Skeleton,
    Col,
    Row,
    Input,
    Progress,
    Table,
    Tag,
    Typography,
    Button,
    Alert,
    List,
    Modal,
    Select,
    Spin,
    Form,
    Divider,
    Space,
    Popconfirm
} from 'antd';
import {
    FileSearchOutlined,
    PlusOutlined,
    DeleteOutlined,
    MessageOutlined,
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import { GET_USERS, GET_CATEGORIES, GET_EMAIL_ORDERS} from '../../queries';
import { UPDATE_EMAIL_ORDER, CREATE_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import { useQuery, useMutation } from '@apollo/client';
import DeleteEmailsButton from  './DeleteEmailsButton';
import history from '../../Utilities/history';
import { toFullWidthKatakana, toHalfWidthKatakana } from '../../Utilities/katakanaWidthConversion';
import { EMAIL_ORDERS_TAB_MAPPING, PATHS } from '../../constants';
import './emailsTable.less';
import { OrderPdf } from '../';
import { useStickyState } from '../../Utilities/useStickyState';
import { getAssineeList } from '../../Utilities/getAssineeList';
import { closableMessage } from '../../Utilities/closableMessage';
import consumerWithToken from '../../cable';

const parseEmail = (email) => {
    const productNameString = email.products.map(p => p.name).join('')
    const productCodeString = email.products.map(p => p.code).join('')
    const customerString = email.customers.map(c => c.code + c.name_one + c.name_two + c.kana_name).join('')

    return {
        id: email.id,
        key: email.first_page_id,
        progress: email.progress,
        timestamp: email.created_at,
        updatedAt: email.updated_at,
        uploader: email.user,
        orderNumber: email.order_number,
        isMultipage: email.num_pages > 1,
        pageNum: email.page_num,
        s3ObjectKey: email.s3_object_key,
        presignedDownloadUrl: email.presigned_download_url,
        memo: email.memo,
        assignee: email.assignee,
        category: { ...email.category, displayName: email.category && email.category.code + ' ' + email.category.name},
        rotation: email.rotation,
        numPages: email.num_pages,
        outOfStock: email.out_of_stock,
        status: email.active_tab,
        productNameString,
        productCodeString,
        customerString,
        activeTab: email.active_tab
    }
}

const EmailsTable = ({ location }) => {
    const savedPaginationParams = localStorage.emailsTable && JSON.parse(localStorage.emailsTable);
    const { path } = useRouteMatch();
    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useStickyState(localStorage.getItem('screwdriverEmailsTableSearchText') || '', 'screwdriverEmailsTableSearchText');
    const [, setOrderListIds] = useStickyState([], 'screwdriverOrderListIds');
    const [showAssignedEmails, setShowAssignedEmails] = useState(parseInt(localStorage.getItem('showCurrentUserUploads')) || false);
    const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
    const [pageNumbers, setPageNumbers] = useState(savedPaginationParams?.pageNumbers || {});
    const [activeTab, setActiveTab] = useStickyState('inbox', 'screwdriverEmailsTableActiveTab')
    const [modalOpen, setModalOpen] = useState(false);
    const [previewPdf, setPreviewPdf] = useState(null);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [assigneesModalOpen, setAssigneesModalOpen] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [assigneeform] = Form.useForm()
    const [categoryModalVisible, setCategoryModalVisible] = useState(false);
    const [orderUpdated, setOrderUpdated] = useState(false);
    const [previewedPdfIds, setPreviewedPdfIds] = useStickyState([], 'previewedPdfIds');
    const [assigneeList, setAssigneeList] = useState([])
    const {
        data,
        error,
        loading,
        refetch,
        startPolling,
        stopPolling
    } = useQuery(GET_EMAIL_ORDERS, {
        fetchPolicy: 'network-only',
        variables: { activeTab: activeTab },
    });

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('currentUser'))?.token;
        const consumer = consumerWithToken(token)
        consumer.subscriptions.create({ channel: 'EmailsChannel' }, {
            received: (data) => {
                if (data?.body.id) {
                    setOriginalRows(prev => {
                        const newRows = [...prev];
                        const orderIndex = prev.findIndex(row => parseInt(row.id) === parseInt(data.body.id))

                        // if we found it in the table
                        if (orderIndex > -1) {
                            // if it's destroyed or it's not on the active tab (which means it moved to another tab), remove it
                            if (data.body.destroyed || data.body.active_tab !== activeTab) {
                                newRows.splice(orderIndex, 1)
                            // if it's not destroyed and it's on the active tab, update it
                            } else if (!data.body.destroyed && data.body.active_tab === activeTab) {
                                newRows[orderIndex] = parseEmail(data.body)
                            }
                        // if we didn't find it
                        } else {
                            // if it's not destroyed and it's on the active tab, add it
                            if (!data.body.destroyed && data.body.active_tab === activeTab) {
                                const parsedEmail = parseEmail(data.body)
                                newRows.unshift(parsedEmail)
                            } 
                            // if it's destroyed or it's not on the active tab, do nothing
                        }

                        return newRows
                    });
                }
            }
        })
        
        return () => {
            consumer.disconnect()
        }
    }, [activeTab])

    const { data: userData, loading: userLoading } = useQuery(GET_USERS);
    const { data: categoryData, loading: categoryLoading, refetch: refetchCategories } = useQuery(GET_CATEGORIES);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('currentUser'))?.token;
        const consumer = consumerWithToken(token)
        consumer.subscriptions.create({ channel: 'UsersChannel' }, {
            received: (data) => {
                setUsers(prev => {
                    const newUsers = [...prev];
                    const userIndex = prev.findIndex(user => parseInt(user.id) === parseInt(data.body.id))
                    newUsers[userIndex].online = data.body.online
                    return newUsers
                })
            }
        })
        
        return () => {
            consumer.disconnect()
        }
    }, [])

    const [memoForm] = Form.useForm();

    const handleMemoSubmit = () => {
        const {memo, id} = memoForm.getFieldsValue(true)
        updateMemo({ variables: { input: { id, memo } } });
        setIsMemoModalVisible(false)
    }

    const [updateMemo] = useMutation(UPDATE_EMAIL_ORDER, {
        onCompleted: () => {
            closableMessage('msgSuccess', 'success', 'メモを保存しました。')
            refetch({variables: { activeTab: activeTab }});
        },
        onError: (error) => {
            error.graphQLErrors.forEach((e) => {
                closableMessage('msgError', 'error', e?.message, 0)
            });
        }   
    })

    const [updateAssignee] = useMutation(UPDATE_EMAIL_ORDER, {
        onCompleted: () => {
            closableMessage('msgSuccess', 'success', '担当者を割り当てました。')
            refetch({variables: { activeTab: activeTab }});
        },
        onError: error => {
            console.log(error);
        }
    });

    const [createCategory] = useMutation(CREATE_CATEGORY, {
        onCompleted: () => {
            closableMessage('msgSuccess', 'success', 'カテゴリーを追加しました。')
            refetchCategories()
            setNewCategory('')
        },
        onError: error => {
            if (error.message.includes('already been taken')) {
                closableMessage('msgTaken', 'error', 'そのカテゴリーは既に存在します', 0)
            } else {
                closableMessage('msgError', 'error', 'カテゴリーの追加に失敗しました', 0)
            }
        }
    })

    const [updateCategory] = useMutation(UPDATE_CATEGORY, {
        onCompleted: () => {
            refetchCategories()
        }
    })

    const [deleteCategory] = useMutation(DELETE_CATEGORY, {
        onCompleted: () => {
            closableMessage('msgSuccess', 'success', 'カテゴリーを削除しました。')
            refetchCategories();
            refetch({variables: { activeTab: activeTab }});
        },
        onError: error => {
            closableMessage('msgError', 'error', '既にEmailに割り当てているため、カテゴリーの削除はできません。', 0)
            console.log(error);
        }
    })

    const [updateOrderNoFlash] = useMutation(UPDATE_EMAIL_ORDER, {
        onCompleted: () => {
            refetch({variables: { activeTab: activeTab }});
        },
        onError: error => {
            closableMessage('msgError', 'error', '情報の変更に失敗しました', 0)
            refetch()
            console.error(error);
        }
    });

    useEffect(() => {
        if (userData?.users) {
            setUsers(userData.users.edges.map(({ node }) => {
                return {
                    ...node,
                    value: node.id,
                    label: node.username,
                    username: node.username,
                    id: node.id
                };
            }));
        }
    }, [userLoading, userData]);

    useEffect(() => {
        if (categoryData?.categories) {
            setCategories(categoryData.categories.edges.sort((a, b) => a.node.sortOrder - b.node.sortOrder)
                .map(({ node }, index) => {
                    return {
                        value: node.id,
                        label: node.displayName,
                        id: node.id
                    };
            }));
        }
    }, [categoryData, categoryLoading]);

    const handleAssigneeChange = (assigneeId, orderId, showFlash = true) => {
        console.log(assigneeId, orderId, showFlash)
        const newRows = [...originalRows];
        const orderIndex = newRows.indexOf(newRows.find(row => parseInt(row.key) === parseInt(orderId)))
        const user = users.find(user => parseInt(user.value) === parseInt(assigneeId));
        newRows[orderIndex].assignee = user
        setOriginalRows(newRows);
        if (showFlash) {
            return updateAssignee({
                variables: {
                    input: {
                        id: orderId,
                        assigneeId
                    }
                }
            });
        } else {
            return updateOrderNoFlash({
                variables: {
                    input: {
                        id: orderId,
                        assigneeId
                    }
                }
            });
        }
    }

    const handleCategoryChange = (categoryId, orderId) => {
        setOrderUpdated(true);
        const newRows = [...originalRows];
        const orderIndex = newRows.indexOf(newRows.find(row => parseInt(row.key) === parseInt(orderId)))
        newRows[orderIndex].category = categoryData.categories.edges.find(cat => parseInt(cat.node.id) === parseInt(categoryId))?.node
        setOriginalRows(newRows);
        return updateOrderNoFlash({
            variables: {
                input: {
                    id: orderId,
                    categoryId: categoryId || null
                }
            }
        });
    }

    const changeOrder = (category, direction) => {
        const categoriesData =  categoryData.categories.edges.map(({ node }) => node)
        const index = categoriesData.findIndex(cat => cat.id === category.id)
        const swappedCategory = direction === 'up' ? categoriesData.at(index - 1) : categoriesData.at((index + 1) % categoriesData.length)
        updateCategory({
            variables: {
                input: {
                    id: category.id,
                    sortOrder: swappedCategory.sortOrder
                }
            }
        })
        updateCategory({
            variables: {
                input: {
                    id: swappedCategory.id,
                    sortOrder: category.sortOrder
                }
            }
        })
    }

    const rowFilter = useCallback(row => {
        const search = searchText.toLowerCase();
        const searchMatch = row.from?.toLowerCase().includes(search)
            || toFullWidthKatakana(row.from?.toLowerCase()).includes(search)
            || toHalfWidthKatakana(row.from?.toLowerCase()).includes(search)
            || row.orderNumber?.includes(search)

        if (showAssignedEmails) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            const uploaderMatch = row.from === currentUser.username;
            if (search !== "") {
                return uploaderMatch && searchMatch;
            } else {
                return uploaderMatch;
            }
        } else {
            if (search !== "") {
                return searchMatch;
            }
            return true;
        }
    }, [showAssignedEmails, searchText]);

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchText(value);
    };

    const addNewCategory = () => {
        const [code, name] = newCategory.split(/\s/);
        if (code && name) {
            createCategory({
                variables: {
                    input: {
                        name,
                        code
                    }
                }
            })
        } else {
            closableMessage('msgFormatError', 'error', 'カテゴリーは次のような書式で追加してください。「A カテゴリー名」 （コードとカテゴリー名の間にスペースが必要です）', 0)
        }
    }

    const removeCategory = (e, id) => {
        e.stopPropagation()
        deleteCategory({
            variables: {
                input: {
                    id
                }
            }
        })
    }

    const showMemoModal = (order) => {
        memoForm.setFieldsValue({ memo: order.memo || '', id: order.key })
        setIsMemoModalVisible(true)
    };

    const hideMemoModal = () => {
        setIsMemoModalVisible(false)
    };

    const handleModalOpen = (order) => {
        memoForm.setFieldsValue({ memo: order.memo || '', id: order.key })
        setModalOpen(true);
        const newPreviewedPdfIds = [...previewedPdfIds].slice(Math.max(previewedPdfIds.length - 50, 0))
        setPreviewedPdfIds([...newPreviewedPdfIds, order.key]);
        setPreviewPdf(order);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setPreviewPdf(null);
    }

    const linkify = rowContent => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
            <Link to={PATHS.emailDetails.replace(':emailId', rowContent.id)}>
                {rowContent.subject}
            </Link>
            <FileSearchOutlined onClick={e => { handleModalOpen(rowContent) }} 
                style={{marginLeft: "auto", fontSize: "1.2rem", color: previewedPdfIds.includes(rowContent.key) ? "black" : "lightgrey"}}
            />
            <MessageOutlined className="memo-button" 
                style={{fontSize: "20px", marginLeft: ".5rem", color: rowContent.memo ? "black" : null, backgroundColor: rowContent.memo ? "#FFFF00" : null}} 
                onClick={() => { showMemoModal(rowContent) }} 
            />
        </div>
    );

    const taggify = (text, oos) => {
        let color;
        let displayText = EMAIL_ORDERS_TAB_MAPPING[text]

        switch (text) {
            case 'inbox':
                color = 'blue';
                break;
            case 'incomplete':
                color = 'orange';
                break;
            case 'backordered':
                color = 'red';
                break;
            case 'complete':
                color = 'green';
                break;
            default:
                color = 'blue';
        }

        if (oos) {
            color = 'red';
            displayText = '在庫切れ';
        }

        return <Tag color= {color}>{displayText}</Tag>;
    };

    const columns = [
        {
            title: <Typography.Text strong>件名</Typography.Text>,
            dataIndex: 'subject',
            render: (text, rowContent) => linkify(rowContent),
        },
        {
            title: <Typography.Text strong>カテゴリー</Typography.Text>,
            dataIndex: 'category',
            render: (category, rowContent) => {
                return <Select 
                            style={{ width: "8rem" }} 
                            value={category?.displayName}
                            allowClear
                            onChange={e => handleCategoryChange(e, rowContent.key)}
                            options={categories || <Spin/>}
                        />
            }, 
            sorter: (a, b) => a.assignee?.username?.localeCompare(b.assignee?.username),
        },
        {
            title: <Typography.Text strong>担当者</Typography.Text>,
            dataIndex: 'assignee',
            render: (assignee, rowContent) => {
                return <Select 
                            style={{ width: "8rem" }} 
                            value={assignee && parseInt(assignee?.id)}
                            onChange={e => handleAssigneeChange(e, rowContent.key)}
                        >
                            {
                                assigneeList?.length > 0 && assigneeList.map((assignee, idx) => 
                                    <Select.Option 
                                        key={idx} 
                                        value={parseInt(assignee.id)} 
                                        style={{ backgroundColor: 
                                            assignee.online 
                                            ? "#FEFEB6" 
                                            : assignee.employmentType === 'hold' 
                                            ? "lightgray" 
                                            :  "" }}
                                    >
                                        <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <Typography.Text strong>
                                                {assignee.username}
                                            </Typography.Text>
                                            <Typography.Text strong>
                                                {assignee.count || '0'}
                                            </Typography.Text>
                                        </Space>
                                    </Select.Option>
                                )
                            }
                        </Select>

            }, 
            sorter: (a, b) => a.assignee?.username?.localeCompare(b.assignee?.username),
        },
        {
            title: <Typography.Text strong>送信者</Typography.Text>,
            dataIndex: 'from',
            render: (from, rowContent) => {
                return from
            }, 
            sorter: (a, b) => a.from?.localeCompare(b.from),
        },
        {
            title: <Typography.Text strong>伝票番号</Typography.Text>,
            dataIndex: 'orderNumber',
            sorter: (a, b) => a.orderNumber?.localeCompare(b.orderNumber),
            multiple: 3
        },
        {
            title: <Typography.Text strong>ステータス</Typography.Text>,
            dataIndex: 'status',
            render: (text, rowContent) => {
                if (rowContent.progress && (rowContent.progress !== 'finished' && rowContent.progress !== 'firstPageFinished')) {
                    let percent;
                    switch (rowContent.progress) {
                        case 'uploaded':
                            percent = 20;
                            break;
                        case 'jpeg_finished':
                            percent = 50;
                            break;
                        case 'prediction_finished':
                            percent = 75;
                            break;
                        default:
                            percent = 0;
                            break;
                    }

                    return <Progress percent={percent} size='small' />;
                } else {
                    return taggify(rowContent.status, rowContent.outOfStock);
                }
            },
            sorter: (a, b) => a.status.localeCompare(b.status),
            multiple: 1,
            width: 150
        },
        {
            title: <Typography.Text strong>アップロード日時</Typography.Text>,
            dataIndex: 'timestamp',
            defaultSortOrder: 'ascend',
            render: timestamp => new Date(timestamp).toLocaleString(),
            sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
            multiple: 2,
            width: 170
        },
        {
            title: <Typography.Text strong>作業日時</Typography.Text>,
            dataIndex: 'updatedAt',
            render: updatedAt => new Date(updatedAt).toLocaleString(),
            sorter: (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
            multiple: 2,
            width: 170
        },
        
    ];
    useEffect(() => {
        if (users && originalRows) {
            setAssigneeList(getAssineeList(users, originalRows))
        }
    }, [users, originalRows])
    
    useEffect(() => {
        if (data) {
            const parsedRows = data.recentEmailOrders.map(emailOrder => {
                // const productNameString = emailOrder.node.products.map(p => p.name).join('')
                // const productCodeString = emailOrder.node.products.map(p => p.code).join('')
                // const customerString = emailOrder.node.customers.map(c => c.code + c.nameOne + c.nameTwo + c.kanaName).join('')

                return {
                    id: emailOrder.id,
                    key: emailOrder.id,
                    progress: emailOrder.progress,
                    timestamp: emailOrder.createdAt,
                    updatedAt: emailOrder.updatedAt,
                    uploader: emailOrder.from,
                    messageId: emailOrder.messageId,
                    subject: emailOrder.subject,
                    to: emailOrder.to,
                    from: emailOrder.from,
                    // orderNumber: emailOrder.orderNumber,
                    // isMultipage: emailOrder.numPages > 1,
                    // pageNum: emailOrder.pageNum,
                    // s3ObjectKey: emailOrder.s3ObjectKey,
                    // presignedDownloadUrl: emailOrder.presignedDownloadUrl,
                    // memo: emailOrder.memo,
                    assignee: emailOrder.assignee,
                    category: emailOrder.category,
                    // rotation: emailOrder.rotation,
                    // numPages: emailOrder.numPages,
                    // outOfStock: emailOrder.outOfStock,
                    // status: emailOrder.activeTab,
                    // productNameString,
                    // productCodeString,
                    // customerString,
                    activeTab: emailOrder.activeTab
                }
            }).sort((a, b) => {
                if (a.timestamp === b.timestamp) {
                    return a.s3ObjectKey.localeCompare(b.s3ObjectKey);
                }
                return new Date(b.timestamp) - new Date(a.timestamp);
            });
            setOriginalRows(parsedRows);
        }
    }, [data, activeTab, startPolling, stopPolling, orderUpdated]); 

    useEffect(() => {
        if (originalRows) {
            const filteredRows = originalRows.filter(rowFilter);
            setRows(filteredRows)
            setOrderListIds(filteredRows.map(row => row.key));
        }
    }, [originalRows, rowFilter, setOrderListIds])
    
    useEffect(() => {
        localStorage.removeItem('orderDetailsActiveTab');
    }, [])

    if (loading) { return <div>
        <Skeleton/>
        <Spin style={{position: "absolute", left: "calc(50% + 50px)", top: "40%"}}/>
    </div>; }

    if (error) {
        if (error.message !== 'Network error: Failed to fetch') {
            authenticationService.logout();
            history.push('/login');
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedOrderIds(selectedRows.map(row => parseInt(row.key)));
        }
    };

    const TableButtons = (
        <Row gutter={12} justify={"end"}>
            <Col span={5} style={{marginRight: "auto"}}>
                <Input.Search placeholder="検索" value={searchText} onChange={handleSearchChange} />
            </Col>
            <Col>
                <Button 
                    onClick={e => setAssigneesModalOpen(true)} 
                    disabled={selectedOrderIds.length === 0}
                >
                    担当者を振り分ける
                </Button>
            </Col>
            <Col>
                <Button onClick={() => {
                    localStorage.setItem('showCurrentUserUploads', showAssignedEmails ? 0 : 1);
                    setShowAssignedEmails(!showAssignedEmails);
                }}>
                    {showAssignedEmails ? '全てのユーザー表示' : 'このユーザーのみ表示'}
                </Button>
            </Col>
            <Col>
                <Row justify="end" type="flex">
                    <Button onClick={e => setCategoryModalVisible(true)}>カテゴリーの編集</Button>
                </Row>
            </Col>
            <Col>
                <Row justify="end" type="flex">
                    <DeleteEmailsButton
                        selectedOrderIds={selectedOrderIds}
                        handleCompleted={ () => { setSelectedOrderIds([]); refetch({ variables: {activeTab: activeTab}}); }} />
                </Row>
            </Col>
        </Row>
    );

    const TableHeader = (
        <div className='table-header' style={{paddingTop: 0}}>
            <Tabs defaultActiveKey={activeTab} onChange={(tab) => {
                setActiveTab(tab);
                setSearchText('');
            }}>
                {Object.keys(EMAIL_ORDERS_TAB_MAPPING).map(k => (
                    <Tabs.TabPane tab={EMAIL_ORDERS_TAB_MAPPING[k]} key={k} >{TableButtons}</Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );

    const failedFilesList = (
        <List
            dataSource={location?.state?.failedFiles}
            renderItem={filename => (
                <List.Item key={filename}>
                    {filename}
                </List.Item>
            )}
        />
    );
   
    const pdfMake = () => { // to make sure we get a fresh instance of orderPdf when a new order is previewed
        return (
            <OrderPdf
                orderId={previewPdf.key}
                key={previewPdf.s3ObjectKey}
                pageWidth={window.innerWidth/2} 
                file={previewPdf.presignedDownloadUrl}
                rotationButtons
                rotation={previewPdf.rotation}
                unmodifiable
                initialPageNum={previewPdf.pageNum}
                scale={2}
            />
        )
    }

    return <Switch>
        <Route exact path={path}>
            { location?.state?.failedFiles?.length > 0 &&
                <Alert
                    type='error'
                    closable
                    message={`以下のファイルのアップロードに失敗しました ${location?.state?.additionalFailedMessage || ''}`}
                    description={failedFilesList}
                    onClose={() => {delete location.state.failedFiles; delete location.state.additionalFailedMessage;}}
                />
            }
            {TableHeader}
            <Modal 
                width={window.innerWidth - 100}
                visible={modalOpen} onCancel={handleModalClose} footer={null}>
                {previewPdf?.presignedDownloadUrl && 
                    pdfMake()
                }
                <Divider/>
                <div style={{maxWidth: "30rem", margin: "0 auto"}}>

                    <Typography style={{marginBottom: ".5rem"}}>{<MessageOutlined style={{fontSize: "16px"}}/>} メモ</Typography>
                    <Form form={memoForm} onFinish={handleMemoSubmit}>
                        <Form.Item name="memo">
                            <Input.TextArea rows={4} allowClear/>
                        </Form.Item>
                        <div style={{ display: 'flex', 'justifyContent': 'flex-end', marginTop: 12 }}>
                            <Button onClick={handleModalClose}>キャンセル</Button>
                            <Button style={{ marginLeft: ".5rem" }} type="primary" htmlType='submit'>保存する</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal title="担当者の振り分け" visible={assigneesModalOpen} onCancel={e => setAssigneesModalOpen(false)} onOk={e => assigneeform.submit()}>
                <Form form={assigneeform} onFinish={({ assignee }) => {
                    setAssigneesModalOpen(false);
                    selectedOrderIds.forEach(orderId => {
                        handleAssigneeChange(assignee, orderId, false);
                    })
                    closableMessage('success', 'success', '担当者を振り分けました');

                }}>
                    <Form.Item name="assignee" label="担当者">
                        <Select 
                            style={{ width: "8rem" }} 
                        >
                            { assigneeList?.length > 0 && assigneeList.map((assignee, idx) => 
                                <Select.Option 
                                    key={idx}
                                    value={assignee.id}
                                    style={{ backgroundColor: 
                                        assignee.online 
                                        ? "#FEFEB6" 
                                        : assignee.employmentType === 'hold' 
                                        ? "lightgray" 
                                        :  "" }}
                                >
                                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Typography.Text strong>
                                            {assignee.username}
                                        </Typography.Text>
                                        <Typography.Text strong>
                                            {assignee.count || '0'}
                                        </Typography.Text>
                                    </Space>
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Form>
                
            </Modal>
            <Modal 
                title="カテゴリーの編集" 
                footer={<Button onClick={e => setCategoryModalVisible(false)} type="primary">OK</Button>} 
                visible={categoryModalVisible} 
                onCancel={e => setCategoryModalVisible(false)} 
            >
                <List
                    size="small"
                    dataSource={categoryData?.categories.edges.sort((a, b) => a.node.sortOrder - b.node.sortOrder)}
                    renderItem={({ node }) => {
                        return (
                            <List.Item style={{display: "flex"}}>
                                {node.displayName}

                                <div style={{marginLeft: "auto", display: "flex", flexDirection: "column", marginRight: ".5rem"}}>
                                    <Button size="small" style={{marginBottom: ".25rem"}} icon={<CaretUpOutlined/>} onClick={e => changeOrder(node, "up")}/>
                                    <Button size="small" icon={<CaretDownOutlined/>} onClick={e => changeOrder(node, "down")}/>
                                </div>
                                <Popconfirm
                                    title="本当にカテゴリーを削除しますか？なお、emailにこのカテゴリが割り当てられている場合はカテゴリは削除できません。"
                                    onConfirm={e => removeCategory(e, node.id)}
                                    okText="削除する"
                                    cancelText="キャンセル"
                                >
                                    <Button icon={<DeleteOutlined/>} style={{ color: "grey"}}/>
                                </Popconfirm>
                                
                            </List.Item>
                        )
                    }}
                />
                <Divider style={{ margin: '8px 0' }}/>
                <Space direction='horizontal' style={{ padding: '8px 8px 4px', marginBottom: "-2rem" }}>
                <Input
                    placeholder="カテゴリーの新規追加"
                    value={newCategory}
                    onChange={(e)=>setNewCategory(e.target.value)}
                />
                <Button icon={<PlusOutlined />} onClick={addNewCategory}>カテゴリー追加</Button>
                </Space>
                
            </Modal>
            <Table
                columns={columns}
                pagination={ {
                    current: pageNumbers[activeTab] || 1,
                    defaultPageSize: (savedPaginationParams && savedPaginationParams.pageSize) || 10,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        localStorage.setItem('emailsTable', JSON.stringify({ pageNumbers: { ...pageNumbers, [activeTab]: page }, pageSize }));
                        setPageNumbers({ ...pageNumbers, [activeTab]: page });
                    }
                } }
                dataSource={rows}
                onChange={(pagination, filters, sorter) => {
                    const sorterPreferences = JSON.parse(localStorage.getItem('emailsTableSort')) || {};
                    sorterPreferences[sorter.field] = sorter.order;
                    localStorage.setItem('emailsTableSort', JSON.stringify(sorterPreferences));
                }}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
            />
                <Modal title='メモの追加' visible={isMemoModalVisible} onCancel={hideMemoModal} footer={null}>
                     <Form form={memoForm} onFinish={handleMemoSubmit}>
                        <Form.Item name="memo">
                            <Input.TextArea rows={4} allowClear/>
                        </Form.Item>
                        <div style={{ display: 'flex', 'justifyContent': 'flex-end', marginTop: 12 }}>
                            <Button onClick={hideMemoModal}>キャンセル</Button>
                            <Button style={{ marginLeft: ".5rem" }} type="primary" htmlType='submit'>保存する</Button>
                        </div>
                    </Form>
                </Modal>
        </Route>
    </Switch>;
};

EmailsTable.propTypes = {
    location: propTypes.object
};

export default EmailsTable;
