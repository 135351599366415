export const getAssineeList = (usersData, faxesData) => {
    if (faxesData.recentFaxes) {
        faxesData = faxesData.recentFaxes
    }
    if (usersData.users) {
        usersData = usersData.users.edges.map(user => user.node)
    }
    if (usersData.length > 0 && faxesData) {
        let userWithCount = []
        usersData.forEach(user => {
            userWithCount.push({ ...user, count: 0 })
        })
        faxesData.forEach(fax => {
            if (fax.assignee && ['uploaded', 'inbox'].includes(fax.activeTab)) {
                const index = userWithCount.findIndex(user => parseInt(user.id) === parseInt(fax.assignee.id))
                userWithCount[index].count += 1
            }
        })
        // filter out screwdriver-inference, chris, yliv, sakamoto_test from users
        const testusers = ['screwdriver-inference', 'chris', 'yliv', 'sakamoto_test', 'testbench']
        userWithCount = Object.values(userWithCount).filter(user => !testusers.includes(user.username))
        // split the userWithCount hash into 3 hashes by employmentType
        const parttimeUsers = Object.values(userWithCount).filter(user => user.employmentType === 'parttime')
        const holdUsers = Object.values(userWithCount).filter(user => user.employmentType === 'hold')
        const employeeUsers = Object.values(userWithCount).filter(user => user.employmentType === 'employee')

        // sort each hash by online, then by count
        const parttimeUsersSorted = Object.values(parttimeUsers)
        .sort((a, b) => {
            if (a.count > b.count) {
                return 1
            } else if (a.count < b.count) {
                return -1
            } else {
                return 0
            }
        })
        .sort((a, b) => {
            if (a.online && !b.online) {
                return -1
            } else if (!a.online && b.online) {
                return 1
            } else {
                return 0
            }
        })
        const holdUsersSorted = holdUsers

        const employeeUsersSorted = Object.values(employeeUsers)
        .sort((a, b) => {
            if (a.count > b.count) {
                return 1
            } else if (a.count < b.count) {
                return -1
            } else {
                return 0
            }
        })
        .sort((a, b) => {
            if (a.online && !b.online) {
                return -1
            } else if (!a.online && b.online) {
                return 1
            } else {
                return 0
            }
        })

        // combine the 3 hashes back into one hash
        userWithCount = [...parttimeUsersSorted, ...holdUsersSorted, ...employeeUsersSorted]

        return userWithCount
    }
}
