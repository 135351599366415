import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_EMAIL } from '../../queries';
import { UPDATE_EMAIL_ORDER } from '../../mutations';
import { Tabs, Spin, Divider, Typography, Form, Input, Row, Col } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { GREY } from '../../constants';
import { useResize } from '../../Utilities/useResize';
import { useStickyState } from '../../Utilities/useStickyState';
import { closableMessage } from '../../Utilities/closableMessage';

const { TextArea } = Input;

const EmailDetails = ({ location }) => {
    const { emailId } = useParams();
    const [form] = Form.useForm();
    const [email, setEmail] = useState(null);
    const [minimizeEmailView] = useStickyState(false, 'minimizeEmailView');
    const [activeTab, setActiveTab] = useStickyState('details', 'emailDetailsActiveTab');
    const [memoText, setMemoText] = useState('');
    const panelRef = useRef(null);

    const { initResize, cursor } = useResize(panelRef, {axis: "horizontal", step: 5, minWidth: 400})


    const [updateEmailOrderMemo] = useMutation(UPDATE_EMAIL_ORDER, {
        onError: (error) => {
            error.graphQLErrors.forEach((e) => {
                closableMessage('updateMemoError', 'error', e?.message, 0);
            });
        },
        onCompleted: () => {
            closableMessage('updateMemoSuccess', 'success', "メモを保存しました。", 3);
        },
    });

    const { data, loading, error } = useQuery(GET_EMAIL, {
        variables: { emailOrderId: parseInt(emailId) },
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.error('GraphQL error:', error);
        }
    });

    useEffect(() => {
        if (data && data.emailOrder) {
            setEmail(data.emailOrder);
            form.setFieldsValue({
                subject: data.emailOrder.subject,
                from: data.emailOrder.from,
                to: data.emailOrder.to,
                // Add more fields as needed
            });
        }
    }, [data, form]);

    const handleMemoTextChange = (e) => {
        const { value } = e.target;
        setMemoText(value);
    };

    const saveMemo = () => {
        updateEmailOrderMemo({ variables: { input: { id: emailId, memo: memoText || null } } })
    };

    if (loading) return <Spin />;
    if (error) return <Typography.Text>Error loading email details</Typography.Text>;

    return (
        <>
            <Row justify='space-between'>
                <Col>
                    <Typography.Title level={5} style={{ alignSelf: 'center', marginBottom: 12}}>
                        Email Details: {email?.subject}
                    </Typography.Title>
                </Col>
            </Row>
            <Divider />
            <div style={{display: "flex"}}>
                <div ref={panelRef} style={{display: "flex", width: minimizeEmailView ? "90%" : "33%"}}>
                    <Form form={form}
                        labelCol={ { span: 22 } }
                        wrapperCol={ { span: 22 } }
                        labelAlign='left'
                        layout='vertical'
                        colon={false}
                        name="saveEmail"
                        style={{width: '100%'}}
                    >
                        <Tabs activeKey={activeTab} onChange={setActiveTab}>
                            <Tabs.TabPane tab='得意先' key='customer'>
                                {/* Add customer content here */}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='商品内容' key='product'>
                                {/* Add product content here */}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='発注番号' key='backorder'>
                                {/* Add backorder content here */}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<span>{memoText?.length > 0 && <BulbOutlined style={{marginRight: ".25rem"}}/>}メモ</span>} key='memo'>
                                <TextArea
                                    data-testid='memo-input'
                                    style={{
                                        width: '95%'
                                    }}
                                    rows={6}
                                    value={memoText}
                                    onChange={handleMemoTextChange}
                                    placeholder='ここに入力してください。'
                                    onBlur={saveMemo}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                    <div style={{width: "5px", height: "100%", cursor: "ew-resize"}} cursor={cursor} onMouseDown={initResize} />
                </div>
                <div style={{ backgroundColor: GREY, marginTop: '-24px', flexGrow: "1", padding: '20px' }}>
                    {email && (
                        <div className="email-content" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
                            <h2>{email.subject}</h2>
                            <p><strong>From:</strong> {email.from}</p>
                            <p><strong>To:</strong> {email.to}</p>
                            <Divider />
                            <div dangerouslySetInnerHTML={{ __html: email.body }} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withRouter(EmailDetails);