import gql from 'graphql-tag';

export const GET_PRESIGNED_UPLOAD_URL = gql`
query presignedUploadUrl($objectKey: String!) {
    presignedUploadUrl(objectKey: $objectKey) {
        objectKey
        expiresAt
        uploadUrl
    }
}`;

export const GET_USERS = gql`
query userList {
    users {
        totalCount
        edges {
            node {
                id
                username
                admin
                email
                employmentType
                online
            }
        }
    }
}
`;

export const GET_CUSTOMERS = gql`
query customersList {
    customers {
        totalCount
        edges {
            node {
                id
                code
                kanaName
                nameOne
                nameTwo
                address
                latitude
                longitude
                zipcode
                phone
                fax
            }
        }
    }
}
`;

export const GET_CUSTOMER = gql`
query customer($id: Int!) {
    customer(id: $id) {
        id
        code
        kanaName
        nameOne
        nameTwo
        address
        latitude
        zipcode
        phone
        fax
        shippings {
            id
            customerCode
            code
            kanaName
            nameOne
            nameTwo
            addressStreet
            addressCity
            address
            zipcode
            phone
            fax
        }
    }
}`


export const GET_SHIPPINGS = gql`
query Shippings {
    shippings {
        totalCount
        edges {
            node {
                id
                customerCode
                code
                kanaName
                nameOne
                nameTwo
                addressStreet
                addressCity
                zipcode
                phone
                fax
            }
        }
    }
}
`;

export const GET_PRODUCTS = gql`
query Products($search: String!) {
    products(search: $search, first: 10) {
        totalCount
        edges {
            node {
                id
                code
                name
                stock
                kikaku1
                kikaku2
                comments
            }
        }
    }
}
`;

export const GET_ALL_PRODUCTS = gql`
query Products {
    products {
        totalCount
        edges {
            node {
                id
                code
                name
                stock
            }
        }
    }
}
`;

export const GET_ORDER_TYPE = gql`
query OrderType($search: String!){
    orderTypes(search: $search) {
        totalCount
        edges {
            node {
                id
                name
                height
                width
            }
        }
    }
}
`;

export const GET_ORDER = gql`
query Order($orderId: Int!){
    order(id: $orderId) {
        id
        wrong
        assignee {
            id
            username
        }
        updatedAt
        s3ObjectKey
        name
        presignedDownloadUrl
        isQuote
        isMultipage
        pageNum
        orderNumber
        memo
        rotation
        fax {
            id
            s3ObjectKey
            firstPageId
            memo
            numPages
            user {
                id
                username
            }
            saveForLater
            progress
            unclassifiable
            done
            assigneeId
            createdAt
            updatedAt
            orders {
                id
                pageNum
            }
        }
        category {
            id
            displayName
        }
        backorders {
            id
            number
        }
        incomplete
        boundingBoxes {
            id
            comment
            rectCoordinates
            updatedAt
        }
        orderType {
            id
            name
            samplePresignedDownloadUrl
            unknown
            height
            width
            customers {
                id
                code
                nameOne
                nameTwo
                kanaName
                address
                latitude
                phone
                fax
                zipcode
                shippings {
                    id
                    kanaName
                    nameOne
                    nameTwo
                    address
                    phone
                    fax
                    zipcode
                    lastSelectedAt
                }
            }
            boundingBoxes {
                id
                comment
                rectCoordinates
                updatedAt
            }
        }
        secondBestOrderType {
            id
            name
            samplePresignedDownloadUrl
        }
        products {
            id
            code
            name
            stock
            kikaku1
            kikaku2
            comments
            productUnit {
                code
                name
            }
        }
        ordersProducts {
            id
            orderId
            productId
            displayOrder
            confidence
            quantity
            quantityConfidence
            page
            isSelected
        }
        shipping {
            id
        }
        customer {
            id
            code
            nameOne
            nameTwo
            kanaName
            address
            latitude
            phone
            fax
            zipcode
            memo
            shippings {
                id
                kanaName
                nameOne
                nameTwo
                address
                phone
                fax
                zipcode
                lastSelectedAt
            }
        }
        inferenceMistakes {
            id
            correctProduct {
                id
                code
            }
            wrongProduct {
                id
                code
            }
        }
    }
}
`;

export const GET_ORDER_PRODUCTS = gql`
query Order($orderId: Int!){
    order(id: $orderId) {
        id
        wrong
        updatedAt
        s3ObjectKey
        name
        isMultipage
        pageNum
        orderNumber
        memo
        products {
            id
            code
            name
            stock
            kikaku1
            kikaku2
            comments
            productUnit {
                name
                code
            }
        }
        ordersProducts {
            id
            orderId
            productId
            displayOrder
            confidence
            quantity
            quantityConfidence
            page
            isSelected
        }

    }
}
`;

export const GET_ORDERS = gql`
query ordersList {
    orders {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                name
                pageNum
                createdAt
                updatedAt
                wrong
                done
                progress
                unclassifiable
                orderType {
                    id
                    name
                }
                saveForLater
                user {
                    id
                    username
                }
            }
        }
    }
}
`;

export const GET_WRONG_ORDERS = gql`
query ordersList{
    orders(search: "wrong=true") {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                name
                pageNum
                unclassifiable
                createdAt
                wrong
                done
                progress
            }
        }
    }
}
`;

export const GET_UNCLASSIFIABLE_ORDERS = gql`
query ordersList{
    orders(search: "unclassifiable=true") {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                name
                createdAt
                unclassifiable
                wrong
                done
                progress
            }
        }
    }
}
`;

export const GET_ORDERTYPE_AGGREGATES = gql`
query aggregatesList {
    dashboardCounts {
        edges {
            node {
                id
                done
                newUpload
                savedForLater
                wrongFormat
                createdAt
                date
            }
        }
    }
}
`;

export const GET_CATEGORIES = gql`
query categoryList {
    categories {
        edges {
            node {
                id
                name
                code
                displayName
                sortOrder
            }
        }
    }
}`

export const GET_BACKORDERS = gql`
query backorderList {
    backorders {
        edges {
            node {
                id
                number
            }
        }
    }
}`

export const GET_FAXES = gql`
query recentFaxes($activeTab: String) {
    recentFaxes(activeTab: $activeTab) {
        id
        s3ObjectKey
        presignedDownloadUrl
        createdAt
        updatedAt
        progress
        orderNumber
        memo
        activeTab
        numPages
        firstPageId
        outOfStock
        category {
            id
            displayName
        }
        assignee {
            id
            username
        }
        user {
            id
            username
        }
        products {
            id
            code
            name
        }
        customers {
            id
            code
            nameOne
            nameTwo
            kanaName
            address
        }
    }
}
`;

export const GET_TAB_COUNT = gql`
query tabCounts($activeTab: String) {
    tabCounts(activeTab: $activeTab)
}
`;

export const GET_ASSIGNEE = gql`
query recentFaxes($activeTab: String) {
    recentFaxes(activeTab: $activeTab) {
        assignee {
            id
            username
        }
        activeTab
    }
}
`;

export const GET_USER_ONLINE = gql`
query users {
    users {
      totalCount
      edges {
        node {
          id
          username
          email
          online
          employmentType
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ORDERS = gql`
query customerOrders($customerId: ID!) {
    customerOrders(customerId: $customerId) {
        id
        s3ObjectKey
        createdAt
        updatedAt
        progress
        orderNumber
        memo
        ordersProducts {
            id
            productId
            quantity
            product {
                name
                code
            }
        }
    }
}
`;


export const GET_PRODUCT_RECOMMENDATIONS = gql`
query productRecommendations($customerId: ID!) {
    productRecommendations(customerId: $customerId) {
        id
        productId
        customerId
        confidence
        product {
            name
            code
        }
        createdAt
    }
}
`;

export const GET_INFERENCE_MISTAKE = gql`
query inferenceMistake($id: Int!) {
    inferenceMistake(id: $id) {
        id
        orderId
        wrongProductId
        correctProductId
        order {
            id
        }
        wrongProduct {
            id
        }
        correctProduct {
            id
        }
        createdAt
        updatedAt
    }
}
`;

export const GET_MONTHLY_SALES_PREDICTIONS = gql`
query monthlySalesPredictions($productId: ID!) {
    monthlySalesPredictions(productId: $productId) {
        id
        productId
        monthStartDt
        quantity
        createdAt
        updatedAt
    }
}
`;

export const GET_PRODUCT = gql`
query product($id: Int!) {
    product(id: $id) {
        id
        code
        name
        stock
        kikaku1
        kikaku2
        comments
        productUnit {
            id
            code
            name
        }
    }
}
`;

export const GET_EMAIL_ORDERS = gql`
query recentEmailOrders($activeTab: String) {
    recentEmailOrders(activeTab: $activeTab) {
        id
        to
        from
        subject
        body
        progress
        messageId
        activeTab
        createdAt
        updatedAt
        assignee {
            id
            username
        }
        category {
            id
            displayName
        }
    }
}
`;

export const GET_EMAIL = gql`
query EmailOrder($emailOrderId: Int!) {
    emailOrder(id: $emailOrderId) {
        id
        to
        from
        subject
        body
        progress
    }
}
`;
